import { getContainer } from '@vegga/front-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('labelMapController', labelMapController);

  labelMapController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    'Restangular',
    '$state',
    'mapsFactory',
    'sectorFactory',
    '$filter',
    'progFactory',
    '$q',
    'manualFactory',
    '$confirm',
    '$timeout',
  ];

  function labelMapController(
    $log,
    $rootScope,
    $scope,
    Restangular,
    $state,
    mapsFactory,
    sectorFactory,
    $filter,
    progFactory,
    $q,
    manualFactory,
    $confirm,
    $timeout
  ) {
    var vm = $scope.$parent.vm;
    vm.label;
    var layerLoaded;
    vm.destroy$ = new Subject();

    activate();

    /*******************/

    function activate() {
      vm.mapsFacade = getContainer().resolve('mapsFacade');
      vm.mapsFacade.markers$.pipe(takeUntil(vm.destroy$)).subscribe((markers) => {
        vm.labelMarkers = markers.filter((item) => item.type === 4);
        if (vm.filter.comp.active && vm.filter.comp.label) loadLabelLayer(vm.labelMarkers);
      });

      vm.addMarkerL = addMarker;
      vm.updateLabel = updateLabel;
      vm.deleteLabelMarker = deleteLabelMarker;
      vm.label = {};
      layerLoaded = false;
    }

    function loadLabelLayer(markers) {
      if (!markers) return;
      if (!vm.map || (!layerLoaded && vm.map?.id !== undefined)) {
        layerLoaded = true;
        vm.labelsInMap = markers;
        loadMarkersToLayer(markers);
        vm.map.addLayer(vm.labelLayer);
        vm.shared = vm.map.shared;
        vm.sharingLvl = vm.map.sharingLvl;
        $scope.$emit('groupElementsInMap', markers);
      } else {
        vm.map.addLayer(vm.labelLayer);
      }
    }

    function addMarker() {
      if (vm.label.prop1 === '') {
        const toast = document.querySelector('vegga-toast-manager');
        toast.create({
          message: $filter('translate')('maps.error.mandatory_text'),
          statusClass: 'error',
        });
        vm.marker.remove();
      } else {
        markerPrepare();
      }
    }

    function markerPrepare() {
      const { layer, latlng } = vm.props;
      layer.setIcon(createLabelIcon(vm.label));

      saveMarker(latlng).then((resp) => {
        layer.properties = {};
        layer.properties.id = resp.id;
        layer.properties.prop1 = resp.prop1;
        layer.properties.prop2 = resp.prop2;
        layer.properties.prop3 = resp.prop3;
        vm.mapsFacade.addMarker(resp);
        vm.labelLayer.addLayer(layer);
        $scope.$emit('completedSave');
        layer.toggleEdit();
        vm.map.off('editable:drawing:commit', markerPrepare);
      });
    }

    function saveMarker(latLng) {
      var params = {};
      params.lat = latLng.lat;
      params.lng = latLng.lng;
      params.mapId = vm.map.id;
      params.userId = $rootScope.user.id;
      params.type = 4;
      params.prop1 = vm.label.prop1;
      params.prop2 = vm.label.prop2 + '';
      params.prop3 = vm.label.prop3 + '';
      //props vm.label
      return mapsFactory.saveMarker(vm.map.id, params);
    }

    function loadMarkersToLayer(markers) {
      if (!markers) return;
      vm.labelLayer.clearLayers();
      _.forEach(markers, (markerInfo) => {
        var marker = L.marker(new L.LatLng(markerInfo.lat, markerInfo.lng));

        marker.setIcon(createLabelIcon(markerInfo));
        marker.properties = {};
        marker.properties.id = markerInfo.id;
        marker.properties.text = markerInfo.prop1;
        vm.labelLayer.addLayer(marker);
      });
      vm.labelLayer.on({
        click: editLabel,
      });
    }

    function createLabelIcon(marker) {
      let class_height = '';
      let class_style_prim = '';

      switch (marker.prop2) {
        case '1':
          class_height = 'small-label';
          break;
        case '2':
          class_height = 'medium-label';
          break;
        case '3':
          class_height = 'big-label';
          break;
      }

      var content = `<div class="label-marker-icon">
                            <div class="badge ${class_height}" style="background-color: ${marker.prop3}"><i class="glyphicon glyphicon-triangle-bottom "></i>
                                <div class="text ${class_style_prim}">${marker.prop1}</div>                          
                            </div>
                        </div>`;

      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    }

    function initAddLabel() {
      vm.label.edit = false;
      vm.label.prop1 = '';
      vm.label.prop2 = '2';
      vm.label.prop3 = null;
    }

    function editLabel(e) {
      vm.editingLabel = e.layer;
      const id = _.get(e, 'layer.properties.id');
      vm.overlay.show();
      $scope.$emit('editSectionChange', { type: 4, elementsInMap: vm.labelsInMap, value: id });

      let lbl;
      mapsFactory.getMarkers(vm.map.id, 4).then((markers) => {
        var mks = markers.plain();
        lbl = _.first(_.filter(mks, { id }));
        vm.label.id = lbl.id;
        vm.label.lat = lbl.lat;
        vm.label.lng = lbl.lng;
        vm.label.mapId = lbl.mapId;
        vm.label.prop1 = lbl.prop1;
        vm.label.prop2 = lbl.prop2;
        vm.label.prop3 = lbl.prop3;
        vm.label.type = lbl.type;
        vm.label.userId = lbl.userId;
        vm.label.edit = true;
      });
    }

    function updateLabel() {
      var params = {};
      if (vm.label.prop1 === '') {
        const toast = document.querySelector('vegga-toast-manager');
        toast.create({
          message: $filter('translate')('maps.error.mandatory_text'),
          statusClass: 'error',
        });
        return;
      }
      params.id = vm.label.id;
      params.lat = vm.label.lat;
      params.lng = vm.label.lng;
      params.mapId = vm.label.mapId;
      params.userId = vm.label.userId;
      params.type = vm.label.type;
      params.prop1 = vm.label.prop1;
      params.prop2 = vm.label.prop2 + '';
      params.prop3 = vm.label.prop3 + '';
      //props vm.label
      mapsFactory.updateMarker(vm.map.id, params).then(() => {
        layerLoaded = false;
      });
    }

    function deleteLabelMarker() {
      mapsFactory.deleteMarker(vm.map.id, vm.label.id).then(() => {
        vm.mapsFacade.deleteMarker(vm.label.id);
        vm.labelLayer.removeLayer(vm.editingLabel);
        layerLoaded = false;
      });
    }

    $scope.$on('addLabelMarker', (e, args) => {
      vm.props = args;
      initAddLabel();
    });

    $scope.$on('closeLabel', () => {
      closeEquipoInfo();
    });

    $scope.$on('deleteLabel', () => {
      deleteLabelMarker();
    });
    $scope.$on('loadLabelsLayer', () => {
      if (vm.filter.comp.active && vm.filter.comp.label) loadLabelLayer(vm.labelMarkers);
    });

    $scope.$on('reloadMap', () => {
      layerLoaded = false;
    });

    $scope.$on('saveEditLabel', () => {
      updateLabel();
      $scope.$emit('savedLabel');
    });

    $scope.$on('editPaginatorChange', (ev, label) => {
      vm.label = label;
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
