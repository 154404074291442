import { getContainer } from '@vegga/front-store';
import { Subject, take, takeUntil } from 'rxjs';
import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('filtersConfigController', filtersConfigController);

  filtersConfigController.$inject = ['$scope', '$state', 'filterFactory', 'unitFactory'];

  function filtersConfigController($scope, $state, filterFactory, unitFactory) {
    var vm = this;
    vm.activeList;
    vm.filtersGroup;
    vm.getUnitsFromFlow;
    vm.save;
    vm.cancel;
    vm.checkUnsigned16;
    vm.currentGroupSelected;
    vm.getUnitsFromFlow = getUnitsFromFlow;
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.checkUnsigned16 = checkUnsigned16;
    vm.setDirty = setDirty;
    vm.index = 0;
    vm.UNITS = UNITS;
    vm.currentState = $state.includes;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$), take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        loadData(currentUnit);
      });
    }

    function loadData(unit) {
      vm.currentUnit = unit;
      if (vm.currentUnit.type === vm.UNITS.A_4000) {
        loadFilters();
      } else {
        vm.switchInput = _.range(0, 11);
        loadFiltersA2();
      }
    }
    function loadFilters() {
      filterFactory.all(vm.currentUnit.id).then(function (data) {
        vm.filtersGroup = data;
        if (vm.filtersGroup && vm.filtersGroup.length > 0) {
          vm.currentGroupSelected = JSON.parse(JSON.stringify(vm.filtersGroup[vm.index]));
        }
      });
    }
    function loadFiltersA2() {
      //A2500 y A5500
      filterFactory.filters(vm.currentUnit.id).then(function (data) {
        var temp = data;
        vm.filtersGroup = [];
        if (temp.filters) {
          vm.filtersGroup[0] = temp.filters[0];
        }
      });
    }

    function getUnitsFromFlow() {
      switch (vm.currentUnit.flow.irrigationUnit) {
        case 0:
          return 'min';
        case 1:
          return 's';
          break;
        case 2:
        case 16:
          return 'm3';
          break;
        case 4:
          return 'l';
          break;
        default:
          return '';
          break;
      }
    }

    function checkUnsigned16(value) {
      if (group.filterUnits < 0) {
        group.filterUnits = 0;
      } else if (group.filterUnits > 65535) {
        //16 bit unsigned integer
        group.filterUnits = 65535;
      }
    }

    function backup() {
      vm.backup = {};
      angular.copy(vm.filtersGroup, vm.backup);
    }

    function cancel_edition() {
      vm.form.$setPristine();
    }

    function save() {
      vm.form.$setPristine();

      if (vm.currentUnit.type === vm.UNITS.A_4000) {
        vm.filtersGroup[vm.index] = vm.currentGroupSelected;
        vm.filtersGroup.forEach((group) => (group.unittype = vm.currentUnit.type));
        vm.filtersGroup.post().then((o) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      } else {
        //A2500 y A5500
        filterFactory.updateFilters(vm.currentUnit.id, [vm.filtersGroup[0]]).then((o) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      }
    }

    function setDirty() {
      vm.form[vm.index].$setDirty();
    }

    $scope.$on('tabChange', (e, args) => {
      if (vm.filtersGroup && vm.filtersGroup.length > 0) {
        vm.index = args;
        vm.currentGroupSelected = JSON.parse(JSON.stringify(vm.filtersGroup[vm.index]));
      }
    });

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    $scope.$on('filtersFormSave', () => {
      save();
    });

    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
